<script lang="ts" setup>
import MarkDownIt from "markdown-it";

const md = new MarkDownIt({ html: true });

const props = withDefaults(
  defineProps<{
    Text: string;
  }>(),
  {}
);
</script>
<template>
  <article class="base-text prose" v-html="md.render(props.Text)"></article>
</template>
